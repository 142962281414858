<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66659 3.66663H18.3333C19.3416 3.66663 20.1666 4.49163 20.1666 5.49996V16.5C20.1666 17.5083 19.3416 18.3333 18.3333 18.3333H3.66659C2.65825 18.3333 1.83325 17.5083 1.83325 16.5V5.49996C1.83325 4.49163 2.65825 3.66663 3.66659 3.66663Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.1666 5.5L10.9999 11.9167L1.83325 5.5" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'EnvelopeCartIcon'
}
</script>
